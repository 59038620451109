import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Blog } from '../components/Blog'

const BlogPage = ({ data }) => {
    const posts = data.allWpPost.nodes
    const { t } = useTranslation()
    const bgColor = '#1b1c22'


    if (!posts.length) {
        return (
            <div style={{backgroundColor:bgColor}}>
                <Layout 
                    header={t("header", { returnObjects: true })}
                    footer={t("footer", { returnObjects: true })}
                    bgColor={bgColor}
                    page='blog'
                    dark
                >
                    <Seo title="Notícias" />
                    <div style={{
                        height:'65vh',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center'
                    }}>
                        <h1 style={{
                            textAlign:'center',
                            textTransform:'uppercase',
                            color:'#eeefed',
                            fontWeight:'200',
                            fontSize:'3rem',
                            zIndex:'2'
                        }}>
                            Não existem notícias.
                        </h1>
                    </div>
                </Layout>
            </div>
        )
    }

    return (
        <div style={{backgroundColor:bgColor}}>
            <Layout 
                header={t("header", { returnObjects: true })}
                footer={t("footer", { returnObjects: true })}
                bgColor={bgColor}
                page='blog'
                dark
            >
                <Seo title="Notícias"/>
                <Blog
                    content={t("blog", { returnObjects: true }).banner}
                    posts={posts}
                />
            </Layout>
        </div>
    )
}

export default BlogPage

export const pageQuery = graphql`
  query WordPressPostArchive($language: String!) {
    allWpPost(sort: { fields: [date], order: DESC }) {
      nodes {
        uri
        date(formatString: "DD MMMM YYYY", locale: "pt")
        title
        excerpt
        featuredImage {
            node {
                altText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED
                            layout: FULL_WIDTH
                            formats: [AUTO, WEBP, JPG]
                            quality: 100
                        )
                    }
                }
            }
        }
      }
    },
    locales: allLocale(filter: {ns: {in: ["blog", "global"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`
