import * as React from "react"
import styled from 'styled-components'
import { Link } from 'gatsby-plugin-react-i18next'
import parse from "html-react-parser"
import { Grid, Cell } from "styled-css-grid"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { media } from './MediaQueries'
import Button from './Button'

const Blog = ( { posts, content }) => {
    const breakpoints = useBreakpoint()
    const title = posts[0].title
    const image = {
        image: getImage(posts[0].featuredImage?.node?.localFile),
        alt: posts[0].featuredImage?.node?.altText || '',
    }
    
    return(
        <BlogStyled>
            <div className='wrapper'>
                <div className='feature-container'>
                    <div className='title'>
                        <h1 dangerouslySetInnerHTML={{ __html: content.feature}}></h1>
                        <hr/>
                    </div>
                    <Grid columns={breakpoints.m ? 1 : 6} gap={breakpoints.m ? '15vw' : '4vw'}>
                        {image?.image &&
                            <Cell width={breakpoints.m ? 1 : 3} className='news' key='featured-image'>
                                        <GatsbyImage image={image.image} alt={image.alt || ''}/>
                            </Cell>
                        }
                        <Cell width={breakpoints.m ? 1 : 3} className='news featured' key='featured-content' middle>
                                <h2>{parse(title)}</h2>
                                <span className='date'>{posts[0].date}</span>
                                <Button 
                                    link={'/blog' + posts[0].uri} 
                                    text='Ler Notícia'
                                    dark
                                />
                        </Cell>
                    </Grid>
                </div>
                
                <div className='news-container'>
                    <div className='title'>
                        <h1 dangerouslySetInnerHTML={{ __html: content.news}}></h1>
                        <hr/>
                    </div>
                    <Grid columns={breakpoints.m ? 1 : 6} gap={breakpoints.m ? '15vw' : '4vw'}>
                        {posts.map(post => {
                            const title = post.title
                            const image = {
                                image: getImage(post.featuredImage?.node?.localFile),
                                alt: post.featuredImage?.node?.altText || '',
                            }

                            return(
                                <Cell width={breakpoints.m ? 1 : 2} className='news' key={post.uri}>
                                    <Link to={'/blog' + post.uri} itemProp='url'>
                                        {image?.image &&
                                            <GatsbyImage image={image.image} alt={image.alt || ''}/>
                                        }
                                        <h2>{parse(title)}</h2>
                                        {/* <span className='description'>{parse(post.excerpt)}</span> */}
                                        <span className='date'>{post.date}</span>
                                    </Link>
                                    <hr/>
                                    <hr/>
                                </Cell>
                            )
                        })}
                    </Grid>
                </div>
            </div>
        </BlogStyled>
    )
}

export { Blog }

const BlogStyled = styled.div`
    .wrapper{
        max-width:1920px;
        margin:0 auto;
        padding: clamp(30px, 5vw, 60px) 5%;
    }

    .title{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: clamp(30px, 5vw, 60px);

        ${media.s`
            flex-direction:column;
            gap: 1rem;
            margin-bottom: 1rem;
        `}

        h1{
            font-weight:100;
            color:#eeefed;
            font-size: 2.5rem;
            text-transform:uppercase;
        }

        hr{
            border-color:#e8eae5;
            width:100%;
            margin-left: 3rem;
            margin-right: 0;

            ${media.l`
                margin-left:2rem;
            `}

            ${media.s`
                display:none;
            `}
        }
    }

    .news{
        position:relative;

        .description{
            color:#eeefed;
            
            .more-link{
                display:none;
            }
        }

        .date{
            font-family:'Exo', sans-serif;
            font-weight:bold;
            color: #eeefed;
            text-transform:uppercase;
            font-size:0.7rem;
            margin-bottom:2rem;
        }

        hr{
            position: absolute;
            height: 100%;
            top: 0;
            left: -7.8%;
            margin:0;
            border-color:#e8eae5;

            :first-child{
                display:none;
            }
        }

        :first-child hr{
            display:none;
        }
    }

    .feature-container{
        padding-bottom: clamp(30px, 5vw, 60px);

        

        h2{
            font-family: 'Cabin', sans-serif;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: #eeefed;
            font-size:2.5rem;
            max-width:550px;
        }

        .featured{
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }
    }

    .news-container{
        

        h2{
            font-family: 'Cabin', sans-serif;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: #eeefed;
            font-size:1rem;
            max-width:400px;
        }

        a{
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }
    } 
`